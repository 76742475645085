/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import translate from 'angular-translate';

import template from './segment-targeting.html';

import resources from '../../../components/api/resources/resources';
import {ADN_TARGETING_TYPES} from "../targeting-constants";
import {TargetingHelper} from "../targeting-helper";

const MODULE_NAME = 'segment-targeting-directive';

angular.module(MODULE_NAME, [translate, resources])

  .directive('adnSegmentTargeting', function(SegmentWithTargeting, UserSegmentList, Segment, EarningsAccount, $log, LocalNetworkProfile) {
    return {
      restrict: 'A',
      require: '^^adnTabset',
      template: template,
      scope: {
        model: '=adnSegmentTargeting',
        runningModel: '<',
        underModel: '=',
        isLockedDown: '<',
        pageType: '<',
        overridableTargeting: '<',
        mode: '@'
      },
      link: function(scope, element, attrs, tabset) {
        scope.model = scope.model || [];
        scope.targetType = ADN_TARGETING_TYPES.userSegment;
        scope.typeOption = {selection: 'ALL'};

        const init = function() {
          scope.inputs = _.cloneDeep(scope.model);
          return TargetingHelper.isComplexSegments(scope.model);
        };
        init();

        scope.isMarketplace = LocalNetworkProfile.isPureMarketplacePlatform();

        if (scope.isMarketplace) {
          EarningsAccount.query({minimal: true}, function (page) {
            scope.publishers = _.map(page.results, function(publisher) {
              return {name: publisher.name, id: publisher.id};
            });
            let allPublishers = {name: 'Across all Publishers', id: 'ALL'};
            scope.publisherOption = {selection: allPublishers};
            scope.publishers.unshift(allPublishers);
          });
        }

        scope.template = {
          loaded: true
        };
        scope.template.complexInput = init();
        scope.loaded = true;

        const translations = {
          CXENSE: {text: 'Cxense', prefix: ''},
          ADOBE: {text: 'Adobe', prefix: 'adobe'},
          RELAY42: {text: 'Relay 42', prefix: 'relay42'},
          ADNUNTIUS: {text: 'Adnuntius', prefix: ''},
          LYTICS: {text: 'Lytics', prefix: 'lytics'},
          GAMMA_SSP: {text: 'Gamma SSP', prefix: 'gamma_ssp'},
          PERMUTIVE: {text: 'Permutive', prefix: 'permutive'},
          SCHIBSTED: {text: 'Schibsted', prefix: ''},
          FIRST_PARTY: {text: 'First Party Data', prefix: ''},
          ADEX: {text: 'The ADEX', prefix: 'adex'}
        };
        const prefixRegExStrings = _.filter(_.map(translations, function(trans) {
          return trans.prefix;
        }), function(p) {
          return p;
        }).join("|");
        const segRefRegEx = new RegExp("(^" + prefixRegExStrings + ").", "g");

        function searchToApiId(entry) {
          const prefix = _.get(translations[entry.dataSource], ['prefix'], '');
          const prefixString = prefix + '.';
          if (prefix && !_.startsWith(entry.id, prefixString)) {
            return prefixString + entry.id;
          }
          return entry.id;
        }

        scope.addEntry = function (entry) {
          const us = angular.copy(entry);
          us.id = searchToApiId(us);
          scope.inputs.push({userSegments: [us], notUserSegments: []});
        };

        scope.addNotEntry = function (entry) {
          const us = angular.copy(entry);
          us.id = searchToApiId(us);
          scope.inputs.push({userSegments: [], notUserSegments: [us]});
        };

        scope.select = function (segment) {
          scope.addEntry(segment);
          segment.selected = true;
        };

        scope.selectOrNot = function (segment) {
          scope.addNotEntry(segment);
          segment.selected = true;
        };

        const getSegmentIdAsReference = function(segId) {
          return (segId || "").replace(segRefRegEx, '');
        };

        scope.removeFromTarget = function(segment) {
          segment = segment || {};
          segment.selected = false;

          let found = false;
          _.forEach(scope.inputs, function(input) {
            ["userSegments", "notUserSegments"].forEach(field => {
              input[field] = _.filter(input[field], function(us) {
                if (!found && ((!segment.id && (!us || !us.id)) || _.get(us, 'id') === _.get(segment, 'id'))) {
                  found = true;
                  return false;
                }
                return true;
              });
            });
          });
          scope.inputs = _.filter(scope.inputs, function(input) {
            return input.userSegments.length > 0 || input.notUserSegments.length > 0;
          });
          (_.find(_.get(scope.list, ['results'], []), ["id", getSegmentIdAsReference(segment.id)]) || {}).selected = false;
        };

        const updateSelectedSearchResults = function() {
          let selIds = _.keyBy(_.map(scope.model, function(segment) {
            return segment.userSegments[0];
          }), 'id');

          _.forEach(_.get(scope.list, ['results'], []), function(a) {
            a.selected = !!selIds[a.id];
            // Strip the name-spacing from the raw segment id
            a.reference = getSegmentIdAsReference(a.id);
            a.translation = translations[a.dataSource].text || a.dataSource;
            a.className = 'label-default';
            a.publishers = _.map(a.publishers, function(publisher) {
              return publisher.name;
            });
          });
        };

        scope.addToRow = function (index, entry) {
          const theIndex = _.isFinite(index) ? index : scope.inputs.length - 1;
          scope.inputs[theIndex].userSegments.push(entry || {});
        };

        scope.addNotToRow = function (index, entry) {
          const theIndex = _.isFinite(index) ? index : scope.inputs.length - 1;
          scope.inputs[theIndex].notUserSegments = scope.inputs[theIndex].notUserSegments || [];
          scope.inputs[theIndex].notUserSegments.push(entry || {});
        };

        scope.removeFromRow = function (parentIndex, index) {
          scope.inputs[parentIndex]['userSegments'].splice(index, 1);
          if (!scope.inputs[parentIndex].userSegments.length && !scope.inputs[parentIndex].notUserSegments?.length) {
            scope.inputs.splice(parentIndex, 1);
          }
        };

        scope.removeNotFromRow = function (parentIndex, index) {
          scope.inputs[parentIndex]['notUserSegments'].splice(index, 1);
          if (!scope.inputs[parentIndex].userSegments.length && !scope.inputs[parentIndex].notUserSegments?.length) {
            scope.inputs.splice(parentIndex, 1);
          }
        };

        scope.getSimplifiedViewUserSegment = userSegment => _.get(userSegment, ["userSegments", "length"]) ? userSegment["userSegments"][0] : userSegment["notUserSegments"][0];

        scope.search = function(newSearch) {
          scope.searchMeta.resolved = false;

          if (newSearch) {
            scope.searchMeta.currentPage = 1;
          }

          let query = {
            filterBy: 'name;description;externalId',
            filterByLike: scope.search.term || '',
            pageSize: scope.searchMeta.pageSize,
            page: scope.searchMeta.currentPage
          };
          if (scope.typeOption.selection !== 'ALL') {
            query.type = scope.typeOption.selection;
          }
          if ((_.get(scope, ['publisherOption', 'selection', 'id']) || 'ALL') !== 'ALL') {
            query.publisherIds = [scope.publisherOption.selection.id];
          }

          const fillingFunction = function(page) {
            scope.list = page;
            scope.list.pageEnd = Math.min(page.totalCount, query.page * query.pageSize);
            scope.list.pageStart = ((query.page - 1) * query.pageSize) + 1;
            scope.list.totalHits = page.totalCount;
            updateSelectedSearchResults();

            scope.searchMeta.resolved = true;
          };

          if (_.isArray(query.publisherIds) || !scope.isMarketplace) {
            scope.limited = false;
            Segment.ogQuery(query).$promise.then(fillingFunction);
          } else {
            scope.limitedType = scope.isMarketplace ? "publisher" : "earnings account";
            scope.limited = true;
            const runningModel = _.cloneDeep(scope.runningModel);
            delete runningModel[ADN_TARGETING_TYPES.semantic.targets];
            SegmentWithTargeting.postTargeting(runningModel, query).then(fillingFunction);
          }
        };

        scope.searchMeta = {
          pageSize: 50,
          currentPage: 1,
          changePage: function() {
            scope.search();
          },
          resolved: true
        };

        scope.hook = {};
        scope.hook.getTemplateData = function() {
          var summary = _.flatten(_.map(scope.model, function(item) {
            return _.map(item.userSegments, 'name');
          })).join(", ");
          return {
            vm: angular.copy(scope.model),
            model: angular.copy(scope.model),
            constant: ADN_TARGETING_TYPES.userSegment,
            summary: summary
          };
        };

        const copyTemplateFunc = function(template) {
          scope.model = angular.copy(template.data.model);
          updateSelectedSearchResults();
        };
        scope.hook.copyTemplate = copyTemplateFunc;


        let prepareForSave = function() {
          scope.model = scope.model || [];
          scope.model.length = 0;
          _.forEach(scope.inputs, function(entry) {
            if (scope.template.complexInput) {
              scope.model.push(entry);
            } else {
              scope.model.push(entry.userSegments.length ? {userSegments: [entry.userSegments[0]]} : {notUserSegments: [entry.notUserSegments[0]]});
            }
          });
          init();
        };

        tabset.register(ADN_TARGETING_TYPES.userSegment.widget, function() {
          if (scope.queryDone) {
            return;
          }
          scope.queryDone = false;

          const userSegmentIds = scope.inputs
            .map(row => [row["userSegments"], row["notUserSegments"]])
            .flatMap(userSegmentArray => userSegmentArray?.flatMap(userSegment => userSegment ?? []) ?? [])
            .flatMap(userSegment => userSegment?.id ?? []);
          UserSegmentList.query(_.map(userSegmentIds)).then(function (page) {
            const selSegments = page.results;

            _.forEach(scope.model, function (model) {
              [model["userSegments"], model["notUserSegments"]]
                .flatMap(userSegmentArray => userSegmentArray?.flatMap(userSegment => userSegment ?? []) ?? [])
                .forEach(userSegment => {
                  if (!userSegment || !userSegment.id) {
                    return;
                  }
                  let foundSegment = _.find(selSegments, function (s) {
                    return s.id === userSegment.id;
                  });
                  if (foundSegment) {
                    userSegment.selected = true;
                    userSegment.reference = getSegmentIdAsReference(foundSegment.id);
                    userSegment.translation = translations[foundSegment.dataSource].text || foundSegment.dataSource;
                    userSegment.className = "label-default";
                    userSegment.objectState = foundSegment.objectState;
                    userSegment.cpm = foundSegment.cpm;
                  } else {
                    userSegment.reference = getSegmentIdAsReference(userSegment.id);
                    userSegment.className = "label-danger";
                    userSegment.translation = "Unknown";
                    userSegment.objectState = "UNINDEXED";
                    $log.warn("Couldn't find selected segment in UserSegmentList", userSegment);
                  }
                });
            });
            scope.inputs = _.cloneDeep(scope.model);
            scope.queryDone = true;
          });
          scope.search();
        }, copyTemplateFunc);


        tabset.callBeforeSubmit(prepareForSave);
        tabset.registerAllTabs(prepareForSave);
      }
    };
  });

export default MODULE_NAME;
